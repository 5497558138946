// src/Features.js
import React from 'react';

const Features = () => {
  return (
    <div>
      <h2>Our Features</h2>
      <p>This is the Features page.</p>
    </div>
  );
}

export default Features;
