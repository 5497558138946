// src/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // We will create this file for styling

const Navbar = () => {
  return (
    <nav className="navbar">
      <h2 className="navbar-logo">My Website</h2>
      <ul className="navbar-links">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">about</Link>
        </li>
        <li>
          <Link to="/inschrijven">inschrijven</Link>
        </li>
        <li>
          <Link to="/features">kampen</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
