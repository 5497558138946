// src/Home.js
import React from 'react';
import Navbar from './Navbar';
import './Home.css'; // We will create this file for styling

const Home = () => {
  return (
    <div className="home-container"> <Navbar />
      <header className="home-header">
       
        <div className="home-header-content">
          
          <h1>Welcome to My Website</h1>
          <p>Your go-to place for awesome content</p>
        </div>
      </header>
      <main className="home-main">
        <section className="home-intro">
          <h2>About Us</h2>
          <p>We are a team of passionate developers creating awesome web applications.</p>
        </section>
        <section className="home-features">
          <h2>Features</h2>
          <ul>
            <li>Easy to use</li>
            <li>Responsive design</li>
            <li>Fast performance</li>
          </ul>
        </section>
      </main>
      <footer className="home-footer">
        <p>&copy; 2024 My Website. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;

